/* eslint-disable max-len */
import React from 'react'
import { object, bool, func, array, string} from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import IconButton from 'components/IconButton'
import ErrorMessage from 'components/ErrorMessage'


function PtdesDrawer(props) {

    const {
        classes,
        isOpen,
        onClose,
        translate,
        rowData,
        mileId,
        setMileId,
        distance,
        setDistance,
        latitude,
        longitude,
        altitude,
        ptDesLookupOther,
        handlePtDesLookupChange,
        handlePtDesLookupDelete,
        validateAndUpdatePtdes,
        latitudeError,
        longitudeError,
        altitudeError,
        handleSetLatitude,
        handleSetLongitude,
        handleSetAltitude,
        isChanged,
        setIsChanged,
        ptDesError,
        ptDesKmMarkError,
        ptCarGroup,
        setPtCarGroup
    } = props



    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
                 <Grid
                    container
                    spacing={2}
                    style={{ width: 500, margin: 16, display: 'flex', justifyContent: 'space-between' }}
                >
                    <Typography variant="h6">{'PtDes aanpassen'}</Typography>
                </Grid>
                <div className={classes.drawerRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex' }}>
                                <Paper style={{ padding: '16px' , width: '100%'}}>
                                    <div style={{ paddingBottom: '16px' }}>
                                        <Typography>{translate('datatable_header_ptcar_line')}: {rowData.ptdesKmMark[rowData.lineIndex].userLineName.length > 0 
                                            ? rowData.ptdesKmMark[rowData.lineIndex].userLineName 
                                            : 'N/A'}</Typography>
                                    </div>

                                    <div className={classes.twoElementDiv} style={{marginBottom : '16px', alignItems: 'start'}}>
                                        <Grid item xs={5}>
                                            <TextField
                                                error={mileId === ''}
                                                label={`${translate('datatable_header_ptdesplatform_milestone')} Id`}
                                                fullWidth={true}
                                                value={mileId}
                                                required={true}
                                                onChange={e => (setIsChanged(true), 
                                                    setMileId(isNaN(parseInt(e.target.value)) 
                                                    ? '' : parseInt(e.target.value)))}
                                            />
                                            <div style={{marginTop: '16px'}}>
                                                <Typography style={{marginTop: '8px'}}>{`${translate('datatable_header_ptdesplatform_milestone')} Id: ${rowData.ptdesKmMark[props.rowData.lineIndex].milestone}`}</Typography>
                                                <Typography>{`DistanceToZero: ${rowData.ptdesKmMark[props.rowData.lineIndex].distanceToZero}`}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                error={distance === ''}
                                                label={translate('ptDesdistance')}
                                                fullWidth={true}
                                                value={distance}
                                                required={true}
                                                onChange={e => (setIsChanged(true), 
                                                    setDistance(isNaN(parseInt(e.target.value)) 
                                                    ? '' : parseInt(e.target.value)))}
                                            />
                                            <TextField
                                                style={{marginTop: '16px'}}
                                                label={'PtCarGroup'}
                                                fullWidth={true}
                                                value={ptCarGroup}
                                                onChange={e => (setIsChanged(true), 
                                                    setPtCarGroup(e.target.value))}
                                            />
                                        </Grid>
                                       
                                    </div> 
                                    <div className={classes.twoElementDiv} style={{marginBottom : '24px'}}>
                                        <Grid item xs={3}>
                                            <TextField
                                                error={latitudeError}
                                                label={`${translate('datatable_header_ptcar_latitude')}`}
                                                fullWidth={true}
                                                value={latitude}
                                                required={true}
                                                onChange={e => (setIsChanged(true), handleSetLatitude(e))}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                error={longitudeError}
                                                label={translate('datatable_header_ptcar_longitude')}
                                                fullWidth={true}
                                                value={longitude}
                                                required={true}
                                                onChange={e => (setIsChanged(true), handleSetLongitude(e))}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                error={altitudeError}
                                                label={translate('datatable_header_ptcar_altitude')}
                                                fullWidth={true}
                                                value={altitude}
                                                onChange={e => (setIsChanged(true), handleSetAltitude(e))}
                                            />
                                        </Grid>
                                       
                                    </div> 
                                    
                                    <div className={classes.twoElementDiv} style={{alignItems: 'start'}}>
                                    <Grid item xs={5.5}>
                                            {[...new Set([...rowData.ptDesLookup.ptDes, 
                                                ...rowData.ptDesLookup.ptCar])].map((element, i) => {
                                                        return(
                                                            <div className={classes.ptdesLookupContainer} key={i}>
                                                                <TextField
                                                                    className={classes.textfield}
                                                                    label={i == 0 ? 'lookUpField': null}
                                                                    fullWidth={true}
                                                                    value={element}
                                                                    disabled
                                                                    />
                                                            </div>
                                                        )
                                                    })
                                                }    
                                        </Grid>
                                        <Grid item xs={5}>
                                            {ptDesLookupOther.map((element, i) => {
                                                        return(
                                                            <div className={classes.ptdesLookupContainer} key={i}>
                                                                <TextField
                                                                    className={classes.textfield}
                                                                    label={i == 0 ? ' ': null}
                                                                    fullWidth={true}
                                                                    value={element}
                                                                    onChange={e => (setIsChanged(true), 
                                                                        handlePtDesLookupChange(e.target.value, i))}
                                                                    />
                                                                {
                                                                    element != '' &&
                                                                    <Delete
                                                                    className={classes.deleteIcon}
                                                                    onClick={() => (setIsChanged(true),
                                                                        handlePtDesLookupDelete(i))}
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                } 
                                        </Grid>
                                    </div>
                                </Paper>
                            </div>   
                        </Grid>
                    </Grid>

                </div>      
                            <div className={classes.saveButton}>
                                {ptDesError != '' || ptDesKmMarkError != '' ? 
                                <>
                                    <ErrorMessage error={ptDesError} spacing={true}/> 
                                    <ErrorMessage error={ptDesKmMarkError} spacing={true}/> 
                                </>
                                :
                                <>
                                    <IconButton disabled={mileId === '' || distance === '' || !isChanged} 
                                    onClick={() => {mileId !== '' && distance !== '' && validateAndUpdatePtdes()}} 
                                    type="submit" icon="save" title={translate('add_user_confirm_save')} />
                                </>
                                }
                            </div>
        </Drawer>
    )
}

PtdesDrawer.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    rowData: object,
    translate: func,
    mileId: string,
    setMileId: func,
    distance: string,
    setDistance: func,
    latitude: string,
    longitude: string,
    altitude: string,
    ptDesLookupOther: array,
    setPtDesLookupOther: func,
    handlePtDesLookupChange: func,
    handlePtDesLookupDelete: func,
    validateAndUpdatePtdes: func,
    latitudeError: bool,
    longitudeError: bool,
    altitudeError: bool,
    handleSetLatitude: func,
    handleSetLongitude: func,
    handleSetAltitude: func,
    isChanged: bool,
    setIsChanged: func,
    ptDesError: string,
    ptDesKmMarkError: string,
    ptCarGroup: string,
    setPtCarGroup: string,
}

export default PtdesDrawer
