const fallbackPath = process.env.REACT_APP_FALLBACK_PERFORMANCES_PATH

export const routeNames = {
    AGENDA: 'agenda',
    PERFORMANCES: 'performances',
    BUNDELS: 'bundels',
    FEEDBACK: 'feedback',
    DOCUMENTS: 'documents',
    USERS: 'users',
    MOBILE_FEATURES: 'mobileFeatures',
    MANAGE: 'manage',
    COMP_REF: 'compositionRef',
    MONITORING: 'monitoring',
    TRAINS: 'trains',
    PT_CAR_MAN: 'ptCarManagement',
    SEMES: 'eSemes',
    ESPEED: "eSpeed",
    INFRASTRUCTURE: "infrastructure",
    GEOLOC: "geoloc",
}

//Third order:
const compositionWithPtCar = {
    moduleBase: '/compositionWithPtcar',
    id: 'performances_label_performance_detail_location',
    path: '/compositionWithPtcar',
}

const trainTaskOverview = {
    moduleBase: '/traintaskoverview',
    id: 'date_lable',
    path: '/traintaskoverview',
}

const ptCarPlatform = {
    moduleBase: '/ptcarplatforms',
    id: 'manage_ptcar_platform_button',
    path: '/ptcarplatforms',
    hidePhone: true,
}

export const Zones = ['IINW - Gent', 'IICE - Centre', 'IINO - Antwerpen', 'IISO - Charleroi', 'IISE - Liège']

const eSemesZoneGen = () => {
    const zones = Zones
    return zones.map(zone => ({
        moduleBase: `/eSemes`,
        id: zone.replace(' - ', '_'),
        path: `/eSemes/zones/${zone.replace(' - ', '')}`,
    }))
}

export const Districts = ['Noord-West', 'Noord-Oost', 'Centraal-Central', 'Sud-Ouest', 'Sud-Est', 'Centrale-directie']
const eSemesDistrictGen = () => {
    const districts = Districts
    return districts.map(district => ({
        moduleBase: `/eSemes`,
        id: district.replace('-', '_'),
        path: `/eSemes/tasks/${district}`,
    }))
}

//Second order:
const myAgenda = {
    moduleBase: '/agenda',
    id: 'module_my_calendar',
    path: path => this.moduleBase + path.toString(),
}

const searchCalendar = {
    moduleBase: '/searchCalendar',
    id: 'module_others_calendar',
    path: '/searchCalendar',
}

const monitorPerformances = {
    moduleBase: '/performances',
    id: 'module_monitor',
    path: '/performances/districts',
    external: false,
    hidePhone: false,
}

const addPerformance = {
    moduleBase: '/manageperformance',
    id: 'module_add_performance',
    path: '/manageperformance',
    hidePhone: true,
}

const emergencyPerformance = {
    moduleBase: '/emergencyPerformance',
    id: 'fallback_performances',
    path: fallbackPath,
    external: true,
    hidePhone: false,
    extraRestriction: ['emergency_performances', 'performances.functionality.management.performances'],
}

const trainPerformances = {
    moduleName: routeNames.TRAINS,
    id: 'module_train_task_overview',
    isCollapsable: true,
    isOpen: false,
    subModules: [compositionWithPtCar, trainTaskOverview],
}

const geolocAdHoc = {
    moduleBase: '/geolocadhoc',
    id: 'module_adhoc',
    path: '/geoloc/adhoc',
}

const geolocDetailLevel = {
    moduleBase: '/geoloc',
    id: 'module_geoloc_detail_level',
    path: '/geoloc/detailLevel',
}

const firstDepartures = {
    moduleBase: '/readyfordeparture',
    id: 'module_ready_for_departure',
    path: '/readyfordeparture',
}

const feedbackOverview = {
    moduleBase: '/performancesfeedback',
    id: 'module_feedback_overview',
    path: '/performancesfeedback',
    hidePhone: true,
}

const feedbackCategories = {
    moduleBase: '/performancesfeedback',
    id: 'module_feedback_categories',
    path: '/feedbackCategories',
    hidePhone: true,
    admin: false,
}

const feedbackCategory_eDrive = {
    moduleBase: '/performancesfeedback',
    id: 'module_feedback_categories_eDrive',
    path: '/feedbackCategories',
    hidePhone: true,
    admin: true,
}

const feedbackCategory_eShunting = {
    moduleBase: '/performancesfeedback',
    id: 'module_feedback_categories_eShunting',
    path: '/feedbackCategoriesEshunting',
    hidePhone: true,
    admin: true,
}

const documentsReport = {
    moduleBase: '/documents',
    id: 'module_rapport',
    path: '/documents',
    hidePhone: true,
}
const documentsMarkAsRead = {
    moduleBase: '/documents',
    id: 'module_markasread',
    path: '/markasread',
    hidePhone: true,
    extraRestriction: ['mark as read', 'documents.functionality.management.documents'],
}
const documentsOrganisations = {
    moduleBase: '/documents',
    id: 'module_extend_org',
    path: '/extendorganisations',
    hidePhone: true,
    extraRestriction: ['edit organisation', 'documents.functionality.management.users'],
}
const documentsSuper = {
    moduleBase: '/documents',
    id: 'module_superuser',
    path: '/superuser',
    hidePhone: true,
    extraRestriction: ['superuser', 'documents.functionality.management.documents'],
}

const compostionDefects = {
    moduleBase: '/compositions',
    id: 'manage_defect_types_button',
    path: '/manageCompositionDefectTypes',
    hidePhone: true,
}

const compostionPneumatic = {
    moduleBase: '/compositions',
    id: 'manage_pneumatic_suspension_restriction_button',
    path: '/manageCompositionPneumaticRestrictions',
    hidePhone: true,
}

const compostionVacma = {
    moduleBase: '/compositions',
    id: 'manage_vacma_restriction_button',
    path: '/manageCompositionVacmaRestrictions',
    hidePhone: true,
}

const compostionVehicleDetails = {
    moduleBase: '/compositions',
    id: 'vehicle_detail_menu_item',
    path: '/vehicleDetails',
    hidePhone: true,
}

const compostionBrakindDetails = {
    moduleBase: '/compositions',
    id: 'braking_details_menu_item',
    path: '/brakingDetailsPulledTrains',
    hidePhone: true,
}

const monitoring = {
    moduleBase: '/monitoring',
    id: 'module_monitoring',
    path: '/monitoring',
    hidePhone: true,
}

const monitoringPerformanceThreshold = {
    moduleBase: '/performancethreshold',
    id: 'module_monitoring_performance_threshold',
    path: '/performancethreshold',
    hidePhone: true,
}

const eSemesZone = {
    moduleName: 'zone',
    id: 'module_esemes_zone',
    isCollapsable: true,
    isOpen: false,
    subModules: [...eSemesZoneGen()],
}

const eSemesDistrict = {
    moduleName: 'district',
    id: 'module_esemes_district',
    isCollapsable: true,
    isOpen: false,
    subModules: [...eSemesDistrictGen()],
}

const PtDes = {
    moduleBase: '/ptdesOverview',
    id: 'module_ptdes',
    path: '/ptdesOverview',
    hidePhone: true,
}

const ptCar = {
    moduleBase: '/ptcarOverview',
    id: 'ptcar_menu_item',
    path: '/ptcarOverview',
    hidePhone: true,
}

//first order
export const menuTree = [
    {
        moduleName: routeNames.AGENDA,
        id: 'module_calendar',
        isCollapsable: true,
        isOpen: false,
        subModules: [myAgenda, searchCalendar],
    },
    {
        moduleName: routeNames.PERFORMANCES,
        id: 'module_performances',
        isCollapsable: true,
        isOpen: false,
        subModules: [monitorPerformances, addPerformance, emergencyPerformance, trainPerformances],
    },
    {
        moduleName: routeNames.BUNDELS,
        id: 'module_bundles',
        isCollapsable: true,
        isOpen: false,
        subModules: [firstDepartures, ptCarPlatform],
    },
    {
        moduleName: routeNames.INFRASTRUCTURE,
        id: 'module_infrastructure',
        isCollapsable: true,
        isOpen: false,
        subModules: [ptCar, PtDes],
    },
    {
        moduleName: routeNames.FEEDBACK,
        id: 'module_feedback',
        isCollapsable: true,
        isOpen: false,
        subModules: [feedbackOverview, feedbackCategories ,feedbackCategory_eDrive, feedbackCategory_eShunting],
    },
    {
        moduleName: routeNames.DOCUMENTS,
        id: 'module_documents',
        isCollapsable: true,
        isOpen: false,
        subModules: [documentsOrganisations, documentsMarkAsRead, documentsReport, documentsSuper],
    },
    {
        moduleName: routeNames.SEMES,
        id: 'module_esemes',
        isCollapsable: true,
        isOpen: false,
        subModules: [eSemesZone, eSemesDistrict],
    },
    {
        moduleName: routeNames.USERS,
        moduleBase: '/users',
        id: 'module_users',
        path: '/users',
        hidePhone: true,
    },
    {
        moduleName: routeNames.MOBILE_FEATURES,
        moduleBase: '/mobileFeatures',
        id: 'module_mobile_features',
        path: '/mobileFeatures',
        hidePhone: true,
        admin: true,
    },
    {
        moduleName: routeNames.MANAGE,
        moduleBase: '/manage',
        id: 'module_manage',
        path: '/manage',
        hidePhone: true,
        admin: true,
    },
    {
        moduleName: routeNames.COMP_REF,
        id: 'module_composition',
        isCollapsable: true,
        isOpen: false,
        subModules: [
            compostionDefects,
            compostionPneumatic,
            compostionVacma,
            compostionVehicleDetails,
            compostionBrakindDetails,
        ],
    },
    {
        moduleName: routeNames.MONITORING,
        id: 'module_monitoring',
        isCollapsable: true,
        isOpen: false,
        subModules: [monitoring, monitoringPerformanceThreshold],
    },
    {
        moduleName: routeNames.ESPEED,
        moduleBase: '/eSpeed',
        id: 'module_espeed',
        path: '/eSpeed',
        hidePhone: true,
    },
    {
        moduleName: routeNames.GEOLOC,
        id: 'module_geoloc',
        isCollapsable: true,
        isOpen: false,
        subModules: [geolocDetailLevel, geolocAdHoc],
    },
]
