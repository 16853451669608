import React from 'react'
import { object, func, string, number, oneOfType } from 'prop-types'
import { DateTime } from 'luxon'
import { Typography } from '@material-ui/core'
import dateParser from 'utils/dateParser'

const SyncInfo = props => {
    const { syncData, identifier, fromDate, translate, toDate, startValue, validityId } = props
    const getDate = time => DateTime.fromSeconds(parseInt(time)).toFormat('dd/MM/yyyy')
    const getTime = date => {
        if (date) return DateTime.fromSeconds(parseInt(date)).toFormat('HH:mm')
    }
    const getEndTime = date => {
        if (date) return DateTime.fromSeconds(parseInt(date)).toFormat('dd/MM/yyyy HH:mm')
    }

    return (
        <div data-testid="syncInfo_root">
            <Typography
                variant={
                    identifier === 'performanceOverview' ||
                    identifier === 'trainTaskOverview' ||
                    identifier === 'readyForDeparture' ||
                    identifier === 'vehicleDetails' ||
                    identifier === 'brakingPulledTrain' ||
                    identifier === 'esemesOverview'
                        ? 'body1'
                        : 'h6'
                }
                data-testid="syncInfo_typo"
                align={'right'}
                style={{ paddingLeft: '20px' }}
            >
                {translate('timepicker_all_' + identifier)}

                {(fromDate ? getDate(fromDate) : dateParser(startValue.toMillis())) +
                    (fromDate > 0
                        ? `${translate('timepicker_from')} ${getTime(fromDate)} ${translate('timepicker_till')} ${getEndTime(
                              toDate
                          )}`
                        : '')}
            </Typography>

            {syncData?.latestSuccessfullSyncTime != null && syncData?.nextSyncTime != null && (
                <Typography align={'right'} data-testid="syncInfo_typo">
                    {`${translate('sync_last_update')} ${DateTime.fromSeconds(syncData.latestSuccessfullSyncTime)
                        .setZone('Europe/Brussels')
                        .toFormat('HH:mm')}, ${translate('sync_next_update')} ${DateTime.fromSeconds(syncData.nextSyncTime)
                        .setZone('Europe/Brussels')
                        .toFormat('HH:mm')}`}
                </Typography>
            )}
            {validityId && (
                <Typography data-testid="typo_validity" style={{ float: 'right' }}>{`${translate(
                    'validityPeriod'
                )} ${validityId}`}</Typography>
            )}
        </div>
    )
}
SyncInfo.propTypes = {
    syncData: object,
    identifier: string,
    translate: func,
    toDate: oneOfType([string, number]),
    fromDate: oneOfType([string, number]),
    startValue: object,
    validityId: number,
}
export default SyncInfo
