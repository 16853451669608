import React  from 'react'
import { withStore } from 'react-ion-store'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'

import Header from 'components/Header'

import styles from './styles'
import withHocs from 'utils/hocs/withHocs'
import Loading from 'components/Loading'

import IconButton from 'components/IconButton'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Paper from '@material-ui/core/Paper'
import ErrorMessage from 'components/ErrorMessage'

import AdHocEvent from './Services/AdHocEvent'


const GeolocAdHocView = (props) => {
    const [adHocLoading, setAdHocLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [openAdHocDialog, setOpenAdHocDialog] = React.useState(false)


    React.useEffect(() => {
        document.title = props.translate('module_adhoc')
    }, [])

    const handleClose = () => {
        setOpenAdHocDialog(false)
    }
    const handleTrigger = async () => {
        if((props.roles && props.roles.geolocAdHoc && props.roles.geolocAdHoc.write === true) ||
            (Array.isArray(props.roles.geolocAdHoc.write) && props.roles.geolocAdHoc?.write.length > 0)){
                handleClose()
                setError(null)
                setAdHocLoading(true)
                try {
                    const response = await AdHocEvent()
                    if(response.status === 200) {
                        setAdHocLoading(false)
                    } else {
                        setAdHocLoading(false)
                        setError("Error with AdHoc event execution!")
                    }
                } catch (error) {
                    setAdHocLoading(false)
                    setError("Error with AdHoc event execution!")
                }
        }
    }

    return (
        <React.Fragment>
            <Header isMenu title={props.translate('module_adhoc')} />
            <div className={props.classes.submenu}>
                <Typography style={{marginBottom: '16px'}} variant="h6">{props.translate('adhoc_event_message')}</Typography>
                {adHocLoading ? <Loading></Loading> : 
                    <>
                        <IconButton
                        className={props.classes.button}
                        title={props.translate('module_adhoc')}
                        onClick={() => {
                            setOpenAdHocDialog(true)
                        }}
                        />
                        {error && 
                            <div  style={{marginTop: '8px'}}>
                                <ErrorMessage error={error} spacing={false} />
                            </div>
                        }
                    </>
                }
                
                <Dialog data-testid="riv_settings_modal" open={openAdHocDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle style={{paddingBottom: "0px"}} id="form-dialog-title">
                        {props.translate('module_adhoc')}</DialogTitle>
                    <Paper>
                        <DialogContent style={{ minWidth: '600px' }}>
                            <Typography>{props.translate('adhoc_dialog_message')}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button data-testid="close_button" onClick={() => handleClose()} color="primary">
                                {props.translate('manage_switch_dialog_cancel')}
                            </Button>
                            <Button data-testid="handle_button" onClick={() => handleTrigger()} color="primary">
                                {props.translate('manage_switch_dialog_apply')}
                            </Button>
                        </DialogActions>
                    </Paper>
                </Dialog>
            </div>
        </React.Fragment>
    )
}

GeolocAdHocView.propTypes = {
    translate: PropTypes.func,
    store: PropTypes.object,
    history: PropTypes.object,
    __ion_status: PropTypes.object,
    roles: PropTypes.object,
    classes: PropTypes.object,
}

export default withHocs(withStore, withStyles(styles))(GeolocAdHocView)
