import union from 'lodash/union'
import lo from 'lodash'

// Roles as defined in analysis:
//   0: "Administrator" AD
//   1: "Monitoring" MV
//   2: "PerformanceViewer" PV
//   3: "PerformanceManager" PM
//   4: "AttendanceViewer" A
//   5: "AttendanceManager" AE
//   6: "IncidentViewer" IC
//   7: "IncidentManager" IM
//   8: "DocumentsViewer" DV
//   9: "ProfileManager" P
//   10: "Driver" TDR
//   11: "YardManager" YM
//   12: "CompositionViewer" CV
//   13: "CompositionEditor" CE
//   14: "Trainstatusviewer" TSTV
//   15: "Trainstatusmanager" TSTM
//   16: "SemesViewer" ESV
//   17: "CompositionValidator"
//   18: "LitteraManagement"
//   19: "ESpeedViewer"
//   20: "ESpeedManager"
//   21: "LitteraViewer"
//   22: "LitteraEditor"
//   23: "LitteraCreator"
//   24: "LitteraDeleter"
//   25: "GeolocManager"
//   26: "FeedbackViewer"
//   27: "FeedbackEditor"
//   28: "EShuntingUser"
//   29: "FetchPerformancesGeoloc"

let functionality = {}
export default roles => {
    if (typeof roles.length === 'undefined') {
        return { accessDenied: { read: true, write: true } }
    }

    const roleMap = {
        performances: {
            read: [],
            functionality: [],
        },
        documents: {
            read: [],
            functionality: [],
        },
        manage: {
            read: [],
            functionality: [],
        },
        users: {
            read: [],
            functionality: [],
        },
        mobileFeatures: {
            read: [],
            functionality: [],
        },
        monitoring: {
            read: [],
            functionality: [],
        },
        performancesfeedback: {
            read: [],
            functionality: [],
        },
        manageperformance: {
            read: [],
            functionality: [],
        },
        readyfordeparture: {
            read: [],
            functionality: [],
        },
        agenda: {
            read: [],
            functionality: [],
        },
        searchagenda: {
            read: [],
            functionality: [],
        },
        managePtcar: {
            read: [],
            functionality: [],
        },
        jsonFormat: {
            read: [],
            functionality: [],
        },
        profiles: {
            read: [],
            functionality: [],
        },
        managePlatforms: {
            read: [],
            functionality: [],
        },
        compositions: {
            read: [],
            functionality: [],
        },
        managePtcarPlatforms: {
            read: [],
            functionality: [],
        },
        performancethreshold: {
            read: [],
            functionality: [],
        },
        traintaskoverview: {
            read: [],
            functionality: [],
        },
        compositionWithPtcar: {
            read: [],
            functionality: [],
        },
        eSemes: {
            read: [],
            functionality: [],
        },
        eSpeed: {
            read: [],
            functionality: [],
        },
        managePtdes: {
            read: [],
            functionality: [],
        },
        geoloc: {
            read: [],
            functionality: [],
        },
        geolocAdHoc: {
            read: [],
            functionality: [],
        },
    }

    roles.forEach(role => {
        const mapValues = determineRoleMapValue(role.organisations)
        switch (role.portalUserRoleId) {
            case 142:
                //Administrator
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.performancesfeedback.read = mapValues(roleMap.performancesfeedback.read)
                roleMap.documents.read = mapValues(roleMap.documents.read)
                roleMap.manage.read = mapValues(roleMap.manage.read)
                roleMap.users.read = mapValues(roleMap.users.read)
                roleMap.mobileFeatures.read = mapValues(roleMap.mobileFeatures.read)
                roleMap.monitoring.read = mapValues(roleMap.monitoring.read)
                roleMap.managePtcar.read = mapValues(roleMap.monitoring.read)
                roleMap.performances.write = mapValues(roleMap.performances.write)
                roleMap.documents.write = mapValues(roleMap.documents.write)
                roleMap.manage.write = mapValues(roleMap.manage.write)
                roleMap.users.write = mapValues(roleMap.users.write)
                roleMap.mobileFeatures.write = mapValues(roleMap.mobileFeatures.write)
                roleMap.manageperformance.write = mapValues(roleMap.manageperformance.write)
                roleMap.manageperformance.read = mapValues(roleMap.manageperformance.read)
                roleMap.readyfordeparture.read = mapValues(roleMap.readyfordeparture.read)
                roleMap.traintaskoverview.read = mapValues(roleMap.traintaskoverview.read)
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.agenda.write = mapValues(roleMap.agenda.write)
                roleMap.searchagenda.read = mapValues(roleMap.searchagenda.read)
                roleMap.searchagenda.write = mapValues(roleMap.searchagenda.write)
                roleMap.jsonFormat.read = mapValues(roleMap.jsonFormat.read)
                roleMap.profiles.read = mapValues(roleMap.profiles.read)
                roleMap.profiles.write = mapValues(roleMap.profiles.write)
                roleMap.managePlatforms.read = mapValues(roleMap.manage.read)
                roleMap.managePtcarPlatforms.read = mapValues(roleMap.manage.read)
                roleMap.compositions.read = mapValues(roleMap.compositions.read)
                roleMap.compositionWithPtcar.read = mapValues(roleMap.compositionWithPtcar.read)
                roleMap.managePtdes.read = mapValues(roleMap.managePtdes.read)

                roleMap.compositions.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.compositions.functionality
                )
                roleMap.performances.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.performances.functionality
                )
                roleMap.users.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.users.functionality
                )
                roleMap.mobileFeatures.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.mobileFeatures.functionality
                )
                roleMap.documents.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.documents.functionality
                )
                roleMap.manage.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.manage.functionality
                )
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                roleMap.searchagenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.searchagenda.functionality
                )
                roleMap.performancethreshold.read = mapValues(roleMap.performancethreshold.read)
                roleMap.eSemes.read = mapValues(roleMap.eSemes.read)
                roleMap.eSemes.write = mapValues(roleMap.eSemes.write)
                roleMap.eSpeed.read = mapValues(roleMap.eSpeed.read)
                roleMap.eSpeed.write = mapValues(roleMap.eSpeed.write)
                roleMap.geoloc.read = mapValues(roleMap.geoloc.read)
                roleMap.geoloc.write = mapValues(roleMap.geoloc.write)
                roleMap.geolocAdHoc.read = mapValues(roleMap.geolocAdHoc.read)
                roleMap.geolocAdHoc.write = mapValues(roleMap.geolocAdHoc.write)
                break
            case 1:
                //"Monitoring" MV
                roleMap.monitoring.read = mapValues(roleMap.monitoring.read)
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                ///// performance is added to show his own performance details
                roleMap.performances.read = mapValues(roleMap.performances.read)

                break
            case 2:
                //"PerformanceViewer" PV
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.performances.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.performances.functionality
                )
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.searchagenda.read = mapValues(roleMap.searchagenda.read)
                roleMap.searchagenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.searchagenda.functionality
                )
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                roleMap.traintaskoverview.read = mapValues(roleMap.traintaskoverview.read)
                roleMap.readyfordeparture.read = mapValues(roleMap.readyfordeparture.read)
                roleMap.manage.read = mapValues(roleMap.manage.read)
                roleMap.manage.write = mapValues(roleMap.manage.write)
                roleMap.manage.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.manage.functionality
                )
                break
            case 3:
                //"PerformanceManager" PM
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.performances.write = mapValues(roleMap.performances.write)
                roleMap.manageperformance.write = mapValues(roleMap.manageperformance.write)
                roleMap.manageperformance.read = mapValues(roleMap.manageperformance.read)
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.searchagenda.read = mapValues(roleMap.searchagenda.read)
                roleMap.searchagenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.searchagenda.functionality
                )
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                roleMap.performances.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.performances.functionality
                )
                roleMap.manage.read = mapValues(roleMap.manage.read)
                roleMap.manage.write = mapValues(roleMap.manage.write)
                roleMap.manage.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.manage.functionality
                )
                roleMap.readyfordeparture.read = mapValues(roleMap.readyfordeparture.read)
                roleMap.traintaskoverview.read = mapValues(roleMap.traintaskoverview.read)

                break
            case 4:
            case 5:
                //"AttendanceManager" AE
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.searchagenda.read = mapValues(roleMap.searchagenda.read)
                roleMap.performances.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.performances.functionality
                )
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                roleMap.searchagenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.searchagenda.functionality
                )
                break
            case 6:
                //"IncidentViewer" IC
                roleMap.performances.read = mapValues(roleMap.performances.read)

                roleMap.performances.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.performances.functionality
                )

                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.agenda.write = mapValues(roleMap.agenda.write)
                roleMap.searchagenda.read = mapValues(roleMap.searchagenda.read)
                roleMap.searchagenda.write = mapValues(roleMap.searchagenda.write)
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                roleMap.searchagenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.searchagenda.functionality
                )
                break
            case 7:
                //"IncidentManager" IM
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.performances.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.performances.functionality
                )
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.searchagenda.read = mapValues(roleMap.searchagenda.read)
                roleMap.searchagenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.searchagenda.functionality
                )
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )

                break
            case 8:
                //"DocumentsViewer" DV
                roleMap.documents.read = mapValues(roleMap.documents.read)

                roleMap.documents.write = mapValues(roleMap.documents.write)
                roleMap.documents.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.documents.functionality
                )
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                ///// performance is added to show his own performance details
                roleMap.performances.read = mapValues(roleMap.performances.read)

                break
            case 9:
                //"ProfileManager" P
                roleMap.users.read = mapValues(roleMap.users.read)
                roleMap.users.write = mapValues(roleMap.users.write)
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                roleMap.users.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.users.functionality
                )
                roleMap.documents.read = mapValues(roleMap.documents.read)
                roleMap.documents.write = mapValues(roleMap.documents.write)
                roleMap.documents.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.documents.functionality
                )
                roleMap.manage.read = mapValues(roleMap.manage.read)
                roleMap.manage.write = mapValues(roleMap.manage.write)
                roleMap.manage.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.performances.functionality
                )
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                ///// performance is added to show his own performance details
                roleMap.performances.read = mapValues(roleMap.performances.read)

                break
            case 10:
                //Driver
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.performances.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.performances.functionality
                )
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.agenda.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.agenda.functionality
                )
                break
            case 11:
                // Yard Manager
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.readyfordeparture.read = mapValues(roleMap.readyfordeparture.read)
                roleMap.traintaskoverview.read = mapValues(roleMap.traintaskoverview.read)
                roleMap.compositionWithPtcar.read = mapValues(roleMap.compositionWithPtcar.read)
                roleMap.compositionWithPtcar.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.compositionWithPtcar.functionality
                )
                break
            case 12:
            case 13:
                // Composition editor
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.traintaskoverview.read = mapValues(roleMap.traintaskoverview.read)
                roleMap.compositionWithPtcar.read = mapValues(roleMap.compositionWithPtcar.read)
                roleMap.traintaskoverview.read = mapValues(roleMap.traintaskoverview.read)
                roleMap.compositionWithPtcar.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.compositionWithPtcar.functionality
                )

                break
            case 14:
            case 15:
                // TrainStatusManager
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.readyfordeparture.read = mapValues(roleMap.readyfordeparture.read)
                roleMap.compositionWithPtcar.read = mapValues(roleMap.compositionWithPtcar.read)
                roleMap.compositionWithPtcar.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.compositionWithPtcar.functionality
                )
                break
            case 16:
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.eSemes.read = mapValues(roleMap.eSemes.read)
                roleMap.eSemes.write = mapValues(roleMap.eSemes.write)

                break
            case 17:
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.performances.read = mapValues(roleMap.performances.read)
                roleMap.traintaskoverview.read = mapValues(roleMap.traintaskoverview.read)
                roleMap.compositionWithPtcar.read = mapValues(roleMap.compositionWithPtcar.read)
                roleMap.traintaskoverview.read = mapValues(roleMap.traintaskoverview.read)
                roleMap.compositionWithPtcar.functionality = determineFunctionalityRoleMapValue(
                    roleMap,
                    role.portalUserRoleId,
                    roleMap.compositionWithPtcar.functionality
                )
                break
            case 19:
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.eSpeed.read = mapValues(roleMap.eSpeed.read)
                break
            case 20:
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.eSpeed.read = mapValues(roleMap.eSpeed.read)
                roleMap.eSpeed.write = mapValues(roleMap.eSpeed.write)
                break
            case 25:
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.geoloc.read = mapValues(roleMap.geoloc.read)
                roleMap.geoloc.write = mapValues(roleMap.geoloc.write)
                break
            case 29:
                roleMap.agenda.read = mapValues(roleMap.agenda.read)
                roleMap.geolocAdHoc.read = mapValues(roleMap.geolocAdHoc.read)
                roleMap.geolocAdHoc.write = mapValues(roleMap.geolocAdHoc.write)
                break
            default:
                break
        }
    })
    return roleMap
}

const customizer = (objValue, srcValue) => {
    if (typeof objValue !== 'object' && typeof srcValue !== 'object') {
        return objValue === true || srcValue === true
    }
}

//merge the functionality roles from all roles together
const compareFunctionality = (previous, current) => {
    if (previous.length === 0) return current

    if (previous === current) {
        return current
    }
    //check if functionality of the roles is different merge them
    // if (previous !== current) {
    return lo.mergeWith(previous, current, customizer)
    // }
}

const determineFunctionalityRoleMapValue = (roleMap, value, funct = []) => {
    switch (value) {
        //case 0: This is the admin role and has access to all possible functionality
        case 142:
            functionality = {
                tasks: true,
                tabs: true,
                sidebar: {
                    view: true,
                    'adapt hours': true,
                    'adapt driver role': true,
                    'change driver': true,
                    signoff: true,
                    validate: true,
                    add_student: true,
                    unassign_driver: true,
                    reset: true,
                    edit_planned_hours: true,
                    print_perf_detail: true,
                    lock_unlock_performance: true,
                },
                management: {
                    performances: {
                        search: true,
                        add: true,
                        delete: true,
                        refresh: true,
                        emergency_performances: true,
                    },
                    tasks: {
                        search: true,
                        add: true,
                        delete: true,
                        'change status': true,
                        reactivate: true,
                        dismiss: true,
                        deleteBulk: true,
                        readyToGo: true,
                        hasTrainLeft: true,
                    },
                    users: {
                        search: true,
                        add: true,
                        edit: true,
                        'edit organisation': true,
                        profileStatus: true,
                    },
                    documents: {
                        'mark as read': true,
                        'restore data': true,
                        superuser: true,
                        search: true,
                        exportReport: true,
                    },
                    performanceDetail: {
                        view: true,
                        driverMessageEdit: true,
                        addTask: true,
                        taskDetail: true,
                        exportDriveDetails: true,
                        lindaActions: true,
                    },
                    dbrights: {
                        changeDBswitch: true,
                        performanceBackUp: true,
                    },
                    feedbackCategory: {
                        view: true,
                    },
                    ptcarOverview: {
                        view: true,
                        edit: true,
                        delete: true,
                        add: true,
                    },
                    ptdesOverview: {
                        view: true,
                        edit: true,
                        delete: true,
                        add: true,
                    },
                    agenda: {
                        performanceReport: true,
                    },
                    searchagenda: {
                        search: true,
                    },
                    linda: {
                        lindaRolesView: true,
                        manageLindaRoles: true,
                    },
                    platforms: {
                        view: true,
                        edit: true,
                        delete: true,
                        addPlatform: true,
                    },
                    ptcarplatforms: {
                        view: true,
                        edit: true,
                        delete: true,
                        addptcarPlatform: true,
                    },
                    compositions: {
                        manageCompositionDefectTypes: true,
                        manageCompositions: true,
                        compositionView: true,
                        generateBrakeBulletin: true,
                    },
                    performancethreshold: {
                        view: true,
                    },
                    ptcarTypesAndClassification: {
                        edit: true,
                        delete: true,
                        view: true,
                    },
                    eSemes: {
                        view: true,
                        edit: true,
                    },
                    eSpeed: {
                        view: true,
                        edit: true,
                    },
                    geoloc: {
                        view: true,
                        edit: true,
                    },
                    geolocAdHoc: {
                        view: true,
                        edit: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 1:
        case 10:
            functionality = {
                management: {
                    agenda: {
                        performanceReport: true,
                    },
                    performances: {
                        search: true,
                        add: true,
                        delete: true,
                        refresh: true,
                        emergency_performances: true,
                    },
                    performanceDetail: {
                        view: true,
                        driverMessageEdit: true,
                        addTask: true,
                        taskDetail: true,
                        exportDriveDetails: true,
                        lindaActions: true,
                    }, 
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 2:
            functionality = {
                tasks: true,
                tabs: true,
                sidebar: {
                    view: true,
                    'adapt hours': false,
                    'adapt driver role': false,
                    'change driver': false,
                    signoff: false,
                    validate: false,
                    unassign_driver: false,
                    print_perf_detail: true,
                },
                management: {
                    performances: {
                        search: false,
                        add: false,
                        delete: false,
                        refresh: true,
                    },
                    performanceDetail: {
                        view: true,
                        driverMessageEdit: false,
                        addTask: false,
                        exportDriveDetails: false,
                        lindaActions: true,
                    },
                    documents: {
                        'restore data': true,
                    },
                    compositions: {
                        manageCompositionDefectTypes: true,
                        manageCompositionPneumaticRestrictions: true,
                        manageCompositionVacmaRestrictions: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 3:
            functionality = {
                tasks: true,
                tabs: true,
                sidebar: {
                    view: true,
                    'adapt hours': false,
                    'adapt driver role': true,
                    'change driver': true,
                    signoff: false,
                    validate: true,
                    unassign_driver: true,
                    reset: true,
                    edit_planned_hours: true,
                    print_perf_detail: true,
                    lock_unlock_performance: true,
                },
                management: {
                    performances: {
                        search: false,
                        add: true,
                        delete: true,
                        refresh: true,
                    },
                    tasks: {
                        search: true,
                        add: true,
                        'change status': true,
                        reactivate: true,
                        dismiss: true,
                        delete: true,
                        deleteBulk: true,
                        readyToGo: true,
                        hasTrainLeft: true,
                    },
                    performanceDetail: {
                        view: true,
                        driverMessageEdit: true,
                        addTask: true,
                        exportDriveDetails: true,
                        taskDetail: true,
                        lindaActions: true,
                    },
                    dbrights: {
                        changeDBswitch: false,
                    },
                    feedbackCategory: {
                        view: false,
                    },
                    documents: {
                        'restore data': true,
                    },
                    compositions: {
                        manageCompositionDefectTypes: true,
                        manageCompositionPneumaticRestrictions: true,
                        manageCompositionVacmaRestrictions: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 4:
            functionality = {
                tasks: true,
                tabs: true,
                sidebar: {
                    view: true,
                    'adapt hours': false,
                    'adapt driver role': false,
                    'change driver': false,
                    signoff: false,
                    validate: false,
                    unassign_driver: false,
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 5:
            functionality = {
                tasks: true,
                tabs: true,
                sidebar: {
                    view: true,
                    'adapt hours': false,
                    'adapt driver role': false,
                    'change driver': false,
                    signoff: true,
                    validate: false,
                    unassign_driver: false,
                    reset: true,
                },
                management: {
                    performances: {
                        search: false,
                        add: false,
                        delete: false,
                    },
                    tasks: {
                        search: false,
                        add: false,
                        'change status': false,
                        reactivate: false,
                        dismiss: false,
                        deleteBulk: true,
                    },
                    performanceDetail: {
                        view: true,
                        driverMessageEdit: false,
                        addTask: false,
                        taskDetail: false,
                        exportDriveDetails: false,
                    },
                    dbrights: {
                        changeDBswitch: false,
                    },
                    feedbackCategory: {
                        view: false,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 6:
            functionality = {
                tasks: true,
                tabs: true,
                sidebar: {
                    view: true,
                    'adapt hours': true,
                    'adapt driver role': false,
                    'change driver': false,
                    signoff: false,
                    validate: false,
                    unassign_driver: false,
                },
                management: {
                    agenda: {
                        performanceReport: true,
                    },
                    searchagenda: {
                        search: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 7:
            functionality = {
                tasks: true,
                tabs: true,
                sidebar: {
                    view: true,
                    'adapt hours': true,
                    'adapt driver role': true,
                    'change driver': true,
                    signoff: true,
                    validate: true,
                    unassign_driver: true,
                },
                management: {
                    agenda: {
                        performanceReport: true,
                    },
                    searchagenda: {
                        search: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 8:
            functionality = {
                management: {
                    documents: {
                        'mark as read': false,
                        'restore data': false,
                        superuser: false,
                        search: true,
                        exportReport: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 9:
            functionality = {
                management: {
                    users: {
                        search: false,
                        add: false,
                        edit: false,
                        'edit organisation': true,
                    },
                    documents: {
                        'mark as read': true,
                        'restore data': false,
                        superuser: true,
                        search: false,
                        exportReport: false,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        // case 10:
        //     functionality = {}
        //     functionality = compareFunctionality(funct, functionality)
        //     break
        case 11:
            functionality = {
                tasks: true,
                tabs: true,
                sidebar: {
                    view: true,
                    'adapt hours': false,
                    'adapt driver role': false,
                    'change driver': false,
                    signoff: false,
                    validate: false,
                    unassign_driver: false,
                },
                management: {
                    compositionWithPtcar: {
                        manageCompositions: true,
                        compositionView: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 12:
            functionality = {
                management: {
                    compositionWithPtcar: {
                        manageCompositions: false,
                        compositionView: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 13:
            functionality = {
                management: {
                    compositionWithPtcar: {
                        manageCompositions: true,
                        compositionView: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 14:
            functionality = {
                management: {
                    compositionWithPtcar: {
                        compositionView: true,
                        manageCompositions: false,
                        generateBrakeBulletin: false,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 15:
            functionality = {
                management: {
                    compositionWithPtcar: {
                        compositionView: true,
                        manageCompositions: true,
                        generateBrakeBulletin: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 16:
            functionality = {
                management: {
                    eSemes: {
                        view: true,
                        edit: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 17:
            functionality = {
                management: {
                    compositionWithPtcar: {
                        manageCompositions: true,
                        compositionView: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 19:
            functionality = {
                management: {
                    eSpeed: {
                        view: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 20:
            functionality = {
                management: {
                    eSpeed: {
                        view: true,
                        edit: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 25:
            functionality = {
                management: {
                    geoloc: {
                        view: true,
                        edit: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        case 29:
            functionality = {
                management: {
                    geoloc: {
                        view: true,
                        edit: true,
                    },
                    geolocAdHoc: {
                        view: true,
                        edit: true,
                    },
                },
            }
            functionality = compareFunctionality(funct, functionality)
            break
        default:
            break
    }
    return functionality
}

const determineRoleMapValue = newValue => previousValue => {
    if ((Array.isArray(newValue) && newValue.includes('2')) || previousValue === true) return true
    else if (!Array.isArray(previousValue)) return newValue
    // remove dups
    else if (Array.isArray(newValue)) return union(previousValue, newValue)
    // no valid response
    else return previousValue
}
