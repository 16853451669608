import React, { Component } from 'react'
import Calendar from '@toast-ui/react-calendar'
import { object, func, string } from 'prop-types'

import { Button, Typography, Grid } from '@material-ui/core'
import { DateTime } from 'luxon'

import { DatePickerWithKeyboard, TimePickerWithKeyboard } from 'components/Pickers'
import 'tui-calendar/dist/tui-calendar.css'
// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-time-picker/dist/tui-time-picker.css'
import { timeParser } from 'utils/timeParser'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

import CalendarHeader from '../CalendarHeader'
import getPerformancesByIdf from './getPerformancesByIdf'
import themeStyle from './CalendarTheme.json'
import { isIdfSameAsLoggedInUser } from 'utils/isAgendaAllowed'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'

import IconButton from 'components/IconButton'
const colors = {
    DRIVING: '#e040fb',
    NONDRIVING: '#8bc34a',
    ABSENCE: '#979797',
}
const dayNames = ['ZO', 'MA', 'DI', 'WOE', 'DO', 'VRIJ', 'ZA']
const dayNamesFr = ['DI', 'LU', 'MA', 'MER', 'JEU', 'VEN', 'SA']

const myTheme = themeStyle[0]

const resetState = {
    scheduleDetailOpen: false,
    selectedSchedule: '',
    selectedData: [],
}

export class CalendarM510 extends Component {
    calendarRef = React.createRef()
    calendarInst = null
    _isMounted = false

    state = {
        view: 'month',
        performances: [],
        trainDriverPerformances: [],
        scheduleDetailOpen: false,
        selectedSchedule: '',
        selectedData: {},
        dateRange: '',
        firstName: '',
        lastName: '',
        gsm: '',
        idfNumber: '',
        selectedDate: new Date(),
        loading: false,
        startTime: new Date(),
        endTime: new Date(),
        useTimeFrame: false,
        originalPerformances: [],
    }

    static propTypes = {
        classes: object.isRequired,
        activeLanguage: object,
        history: object,
        translate: func,
        match: object,
        store: object,
        width: string,
    }
    handleDateChange = date => {
        this.setState({ selectedDate: date }, () => {
            const { trainDriverPerformances } = this.state
            if (!isNaN(date)) {
                let dateSelected = new Date(date)
                if (
                    trainDriverPerformances &&
                    trainDriverPerformances.length &&
                    dateSelected.getMonth() === new Date(trainDriverPerformances[0].performanceDate).getMonth()
                ) {
                    let calendarInstanceLocal = this.calendarRef.current.getInstance()
                    calendarInstanceLocal.setDate(new Date(this.state.selectedDate))
                    this.changeView('day')
                    return
                }
                //Get new data from the server.
                this.getPerformancesCall(this.state.idfNumber, false)
                const calendarInstance = this.calendarRef.current.getInstance()
                calendarInstance.setDate(new Date(this.state.selectedDate))
                this.changeView('day')
            }
        })
    }
    handleStartHour = time => {
        if (time !== null) this.setState({ startTime: time })
    }
    handleEndHour = time => {
        if (time !== null) this.setState({ endTime: time, useTimeFrame: true })
    }
    resetTimeFrame() {
        this.setState({ useTimeFrame: false, startTime: new Date(), endTime: new Date(), selectedDate: new Date() })
        this.setCalendarData(new Date())
        const calendarInstance = this.calendarRef.current.getInstance()
        calendarInstance._renderDate = new Date()
    }

    filterPerformanceOnTime() {
        const p = this.state.performances
        const originalPerformances = this.state.originalPerformances
        const startTime = DateTime.fromMillis(new Date(this.state.startTime).getTime()).get('hour')
        const endTime = DateTime.fromMillis(new Date(this.state.endTime).getTime()).get('hour')
        return p.filter(perf => {
            const originalPerf = originalPerformances.find(x => x.performanceId === perf.id)
            if (!originalPerf) {
                return false
            }

            const plannedStartTime = DateTime.fromSeconds(originalPerf.plannedStartTime).get('hour')
            return plannedStartTime >= startTime && plannedStartTime <= endTime
        })
    }
    handleClickNextButton() {
        const calendarInstance = this.calendarRef.current.getInstance()
        calendarInstance.next()
        let date = new Date(calendarInstance.getDate())
        if (date < this.getMaxDate()) {
            this.setCalendarData(date)
        } else {
            calendarInstance.prev()
        }
    }
    handleClickNow = () => {
        let d = new Date()
        this.handleDateChange(d)
    }
    handleClickPreviousButton() {
        const calendarInstance = this.calendarRef.current.getInstance()
        calendarInstance.prev()
        let date = new Date(calendarInstance.getDate())
        if (date > this.getMinDate()) {
            this.setCalendarData(date)
        } else calendarInstance.next()
    }

    setCalendarData = async date => {
        const { view, trainDriverPerformances } = this.state
        if (view === 'month') {
            const calendarInstance = this.calendarRef.current.getInstance()
            this.setState({ selectedDate: date}, async () => {
                this.setRenderRangeText()
                let postData = this.getDataObjForRequest(this.state.idfNumber, date, calendarInstance)
                this.setState({ loading: true })
                let data = await getPerformancesByIdf(postData)
                this.setDataFromApiCall(data)
                this.setState({ loading: false })
            })        
        }
        if (view === 'week' || view === 'day') {
            if (
                trainDriverPerformances &&
                trainDriverPerformances.length &&
                date.getMonth() === new Date(trainDriverPerformances[0].performanceDate).getMonth()
            ) {
                this.setState({ selectedDate: date }, () => {
                    this.setRenderRangeText()
                })
            } else {
                let postData = this.getDataObjForRequest(this.state.idfNumber, date)
                this.setState({ loading: true })
                let data = await getPerformancesByIdf(postData)

                this.setDataFromApiCall(data)
                this.setState({ selectedDate: date, loading: false }, () => {
                    this.setRenderRangeText()
                })
            }
        }
    }
    changeView = type => {
        if (this.state.view !== type) {
            this.setState(
                {
                    view: type,
                },
                () => {
                    this.setRenderRangeText()
                }
            )
        }
        this.setRenderRangeText()
    }

    getDataObjForRequest(idf, date, instance = null) {
        let postData
        if (instance) {
            const startDay =
                instance.getDateRangeStart().getDate() < 10
                    ? `0${instance.getDateRangeStart().getDate()}`
                    : `${instance.getDateRangeStart().getDate()}`

            // add +1 for months
            const startMonth =
                instance.getDateRangeStart().getMonth() + 1 < 10
                    ? `0${instance.getDateRangeStart().getMonth() + 1}`
                    : `${instance.getDateRangeStart().getMonth() + 1}`
            const endDay =
                instance.getDateRangeEnd().getDate() < 10
                    ? `0${instance.getDateRangeEnd().getDate()}`
                    : `${instance.getDateRangeEnd().getDate()}`
            // add +1 for months
            const endMonth =
                instance.getDateRangeEnd().getMonth() + 1 < 10
                    ? `0${instance.getDateRangeEnd().getMonth() + 1}`
                    : `${instance.getDateRangeEnd().getMonth() + 1}`

            const rangeStart = `${startDay}${startMonth}${instance.getDateRangeStart().getFullYear()}`
            const rangeEnd = `${endDay}${endMonth}${instance.getDateRangeEnd().getFullYear()}`
            postData = {
                idf: idf,
                startDate: rangeStart,
                endDate: rangeEnd,
            }
            return postData
        }

        let startDayIfInstanceNull = '01'
        let currentMonth = date.getMonth() + 1
        let month = ('0' + currentMonth).slice(-2) // +1 for the current month as month start from 0
        let year = date.getFullYear()
        let endDayIfInstanceNull = new Date(year, currentMonth, 0).getDate()

        postData = {
            idf: idf,
            startDate: startDayIfInstanceNull + month + year,
            endDate: endDayIfInstanceNull + month + year,
        }
        return postData
    }

    getPerformancesCall = async (idf, getDefaultMonthPerf, inst = null) => {
        let date
        if (!getDefaultMonthPerf) date = new Date(this.state.selectedDate)
        else date = new Date()
        let postData = this.getDataObjForRequest(idf, date, inst)
        const data = await getPerformancesByIdf(postData)
        if (this._isMounted) this.setDataFromApiCall(data, getDefaultMonthPerf)
    }

    setDataFromApiCall(data) {
        if (data !== '') {
            let schedules = this.mapToSchedules(data.performanceList)
            this.setState({
                firstName: data.firstName,
                lastName: data.lastName,
                gsm: data.gsmNumber,
                trainDriverPerformances: data.performanceList,
                performances: schedules,
                originalPerformances: data.performanceList,
            })
        } else {
            this.setState({
                firstName: '',
                lastName: '',
                gsm: '',
                trainDriverPerformances: [],
                trainDriverPerformancesForCustomDay: [],
            })
        }
    }
    checkIfOvernight = (start, end) => {
        const startDay = DateTime.fromSeconds(start).day
        const endDay = DateTime.fromSeconds(end).day

        if (startDay !== endDay) {
            return DateTime.fromSeconds(start).endOf('day')
        }

        return DateTime.fromSeconds(end)
    }

    //this function fixes the calendar known TimeZoneIssues
    checkDatesForTimeZone(date) {
        let checkDate = typeof date === 'number' ? DateTime.fromSeconds(date) : date

        return checkDate.toISO()
    }

    mapToSchedules = data => {
        let schedules = []
        data.map(item => {
            return schedules.push({
                calendarId: '0',
                title: item.remark,
                category: item.type.toUpperCase() === 'ABSENCE' ? 'allday' : 'time',
                dueDateClass: '',
                start: this.checkDatesForTimeZone(item.plannedStartTime),
                end: this.checkDatesForTimeZone(this.checkIfOvernight(item.plannedStartTime, item.plannedEndTime)),
                id: item.performanceId,
                bgColor: colors[item.type], //dependant on type
                borderColor: '#979797',
                customStyle: item.type.toUpperCase() === 'ABSENCE' ? 'pointer-events:none' : 'pointer-events:auto',
            })
        })

        return schedules
    }
    handleClickSchedule = e => {
        const { id } = e.schedule
        const bodyObj = this.state.originalPerformances.find(x => x.performanceId === id)

        if (!bodyObj || bodyObj.type.toUpperCase() === 'ABSENCE') {
            return false
        }

        let idf = this.state.idfNumber

        if (!bodyObj.performanceSelectedTime) {
            window.open(`/performances/detail/${bodyObj.performanceId}/profile/${idf}`)
        } else if (
            isIdfSameAsLoggedInUser(idf, this.props.store) ||
            !checkFunctionalityDisabled(this.props.store, 'performanceReport', 'agenda.functionality.management.agenda')
        )
            window.open(
                // eslint-disable-next-line max-len
                `/performancereport/${bodyObj.performanceNumber}/${idf}/${bodyObj.symbolicNameDepot}/${bodyObj.plannedStartTime}`
            )
    }

    onScheduleClose = () => {
        this.setState({ ...resetState })
    }

    componentDidMount = async () => {
        this._isMounted = true
        this.calendarInst = this.calendarRef.current.getInstance()
        let idf = this.props.match.params.idfNumber

        //do dataCall here
        await this.getPerformancesCall(idf, true, this.calendarRef.current.getInstance())
        if (this._isMounted) this.setState({ idfNumber: idf })
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    componentDidUpdate = () => {
        if (this.calendarInst !== null && this.calendarInst.getDate() !== null && this.state.dateRange === '') {
            this.setRenderRangeText()
        }
    }

    setRenderRangeText() {
        const { activeLanguage } = this.props
        let months = [
            'Januari',
            'Februari',
            'Maart',
            'April',
            'Mei',
            'Juni',
            'Juli',
            'Augustus',
            'September',
            'Oktober',
            'November',
            'December',
        ]
        if (activeLanguage.code === 'fr')
            months = [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Août',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre',
            ]

        const view = this.calendarInst.getViewName()
        const calDate = this.calendarInst.getDate()
        const rangeStart = this.calendarInst.getDateRangeStart()
        const rangeEnd = this.calendarInst.getDateRangeEnd()

        let year = calDate.getFullYear()
        let month = calDate.getMonth()
        let date = calDate.getDate()
        let dateRangeText = ''
        let endMonth, endDate, start, end
        switch (view) {
            case 'month':
                dateRangeText = `${months[month]} ${year}`
                break
            case 'week':
                year = rangeStart.getFullYear()
                month = rangeStart.getMonth()
                date = rangeStart.getDate()
                endMonth = rangeEnd.getMonth()
                endDate = rangeEnd.getDate()

                start = `${date < 10 ? '0' : ''}${date} ${months[month]} ${year}`
                end = `${endDate < 10 ? '0' : ''}${endDate} ${months[endMonth]} ${year}`
                dateRangeText = `${start} ~ ${end}`
                break
            default:
                dateRangeText = `${date < 10 ? '0' : ''}${date}  ${months[month]} ${year}`
        }

        this.setState({ dateRange: dateRangeText })
    }

    getMinDate() {
        var oneYearBack = new Date()
        oneYearBack.setFullYear(oneYearBack.getFullYear() - 1)
        return oneYearBack
    }
    getMaxDate() {
        var oneMonthForward = new Date()
        oneMonthForward.setMonth(oneMonthForward.getMonth() + 1)
        return oneMonthForward
    }
    getPerformances() {
        if (this.state.useTimeFrame) return this.filterPerformanceOnTime()
        else return this.state.performances
    }
    render() {
        const { classes, activeLanguage, translate } = this.props
        const { view, dateRange, loading } = this.state
        let performances = this.getPerformances()
        let originalPerformances = this.state.originalPerformances
        let isMobileView = false
        if (isWidthDown('sm', this.props.width)) {
            isMobileView = true
        }
        const desktopView = (
            <React.Fragment>
                <Button
                    disabled={loading}
                    className={classes.calendarButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.handleClickPreviousButton()
                    }}
                >
                    {translate('driver_agenda_button_previous')}
                </Button>
                <Button
                    disabled={loading}
                    className={classes.calendarButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.handleClickNow()
                    }}
                >
                    {translate('driver_agenda_button_today')}
                </Button>
                <Button
                    disabled={loading}
                    className={classes.calendarButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.handleClickNextButton()
                    }}
                >
                    {translate('driver_agenda_button_next')}
                </Button>
                <Button
                    disabled={loading}
                    className={classes.calendarButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.changeView('day')
                    }}
                >
                    {translate('driver_agenda_button_day')}
                </Button>
                <Button
                    disabled={loading}
                    className={classes.calendarButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.changeView('week')
                    }}
                >
                    {translate('driver_agenda_button_week')}
                </Button>
                <Button
                    disabled={loading}
                    className={classes.calendarButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.changeView('month')
                    }}
                >
                    {translate('driver_agenda_button_month')}
                </Button>
                <DatePickerWithKeyboard
                    label={translate('select_date')}
                    animateYearScrolling
                    value={this.state.selectedDate}
                    onChange={e => this.handleDateChange(e)}
                    format="dd/MM/yyyy"
                    minDate={this.getMinDate()}
                    maxDate={this.getMaxDate()}
                />
                <TimePickerWithKeyboard
                    label={translate('start_time')}
                    value={this.state.startTime}
                    onChange={e => this.handleStartHour(e)}
                    disabled={view !== 'month'}
                />
                <TimePickerWithKeyboard
                    label={translate('end_time')}
                    value={this.state.endTime}
                    onChange={e => this.handleEndHour(e)}
                    disabled={view !== 'month'}
                />
                <Button
                    disabled={loading}
                    className={classes.calendarButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.resetTimeFrame()
                    }}
                >
                    {translate('agenda_reset_button')}
                </Button>
                <Typography variant="h4">{dateRange || ''}</Typography>
            </React.Fragment>
        )
        const mobileView = (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <IconButton
                            title=""
                            variant="contained"
                            icon="navigationPrevious"
                            color="primary"
                            className={classes.calendarButton}
                            disabled={loading}
                            onClick={() => {
                                this.handleClickPreviousButton()
                            }}
                        ></IconButton>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton
                            title=""
                            variant="contained"
                            icon="today"
                            color="primary"
                            className={classes.calendarButton}
                            disabled={loading}
                            onClick={() => {
                                this.handleClickNow()
                            }}
                        ></IconButton>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton
                            title=""
                            variant="contained"
                            icon="navigationNext"
                            color="primary"
                            className={classes.calendarButton}
                            disabled={loading}
                            onClick={() => {
                                this.handleClickNextButton()
                            }}
                        ></IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton
                            title=""
                            variant="contained"
                            icon="viewDay"
                            color="primary"
                            className={classes.calendarButton}
                            disabled={loading}
                            onClick={() => {
                                this.changeView('day')
                            }}
                        ></IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton
                            title=""
                            variant="contained"
                            icon="viewWeek"
                            color="primary"
                            className={classes.calendarButton}
                            disabled={loading}
                            onClick={() => {
                                this.changeView('week')
                            }}
                        ></IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton
                            title=""
                            variant="contained"
                            icon="viewAgenda"
                            color="primary"
                            className={classes.calendarButton}
                            disabled={loading}
                            onClick={() => {
                                this.changeView('month')
                            }}
                        ></IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            disabled={loading}
                            className={classes.calendarButton}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.resetTimeFrame()
                            }}
                        >
                            {translate('agenda_reset_button')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <DatePickerWithKeyboard
                            label={translate('select_date')}
                            animateYearScrolling
                            value={this.state.selectedDate}
                            onChange={e => this.handleDateChange(e)}
                            format="dd/MM/yyyy"
                            minDate={this.getMinDate()}
                            maxDate={this.getMaxDate()}
                            className={classes.calendarDatePicker}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimePickerWithKeyboard
                            label={translate('start_time')}
                            value={this.state.startTime}
                            onChange={e => this.handleStartHour(e)}
                            disabled={view !== 'month'}
                            className={classes.calendarDatePicker}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimePickerWithKeyboard
                            label={translate('end_time')}
                            value={this.state.endTime}
                            onChange={e => this.handleEndHour(e)}
                            disabled={view !== 'month'}
                            className={classes.calendarDatePicker}
                        />
                    </Grid>
                </Grid>
            </div>
        )

        return (
            <div className={classes.calendarContainer}>
                <CalendarHeader
                    gsm={this.state.gsm}
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    idfNumber={this.state.idfNumber}
                />
                {isMobileView ? mobileView : desktopView}
                <Calendar
                    usageStatistics={false}
                    height="700"
                    theme={myTheme}
                    disableDblClick={true}
                    disableClick={false}
                    isReadOnly={true}
                    schedules={[...performances]}
                    useDetailPopup={false}
                    useCreationPopup={false}
                    onClickSchedule={e => {
                        this.handleClickSchedule(e)
                    }}
                    week={{
                        startDayOfWeek: 1,
                        daynames: activeLanguage && activeLanguage.code === 'fr' ? dayNamesFr : dayNames,
                    }}
                    month={{
                        startDayOfWeek: 1,
                        daynames: activeLanguage && activeLanguage.code === 'fr' ? dayNamesFr : dayNames,
                    }}
                    scheduleView={['allday', 'time']}
                    taskView={false}
                    allDay={true}
                    ref={this.calendarRef}
                    view={view}
                    template={{
                        timegridDisplayPrimaryTime: function(time) {
                            return `${time.hour}:${time.minutes}0  `
                        },
                        allday: schedule => {
                            const scheduleObj = originalPerformances.find(x => x.performanceId === schedule.id)
                            return `${activeLanguage.code === 'fr' ? scheduleObj.absenceCodeFr : scheduleObj.absenceCodeNl}`
                        },
                        time: schedule => {
                            const bodyObj = originalPerformances.find(x => x.performanceId === schedule.id)
                            if (!bodyObj) {
                                return ''
                            }
                            let styleCss = isMobileView ? `style="padding:0; font-size:8px"` : `style="font-size:10px"`
                            if (this.calendarInst.getViewName() === 'month')
                                return `<strong ${styleCss}> ${bodyObj.performanceNumber} ${
                                    bodyObj.symbolicNameDepot
                                } ${timeParser(bodyObj.plannedStartTime)} ${timeParser(bodyObj.plannedEndTime)}</strong>  `

                            return `<p style = "font-size:10px">${bodyObj.performanceNumber} ${bodyObj.symbolicNameDepot}</p>
                                  <p style = "font-size:10px"> ${timeParser(bodyObj.plannedStartTime)} - ${timeParser(
                                bodyObj.plannedEndTime
                            )}<p>
                                  <p style = "font-size:10px">Type: ${bodyObj.type ? bodyObj.type : 'driving'}</p>
                                  <br />
                                  <p style = "font-size:10px">${
                                      bodyObj.remark && bodyObj.remark.length ? bodyObj.remark : 'Geen opmerkingen'
                                  }</p>`
                        },
                    }}
                />
            </div>
        )
    }
}

export default withWidth()(CalendarM510)
