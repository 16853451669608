/* eslint-disable max-len */
import React, { useState, createContext } from 'react'
import { object, number, element, bool, string } from 'prop-types'
import { DateTime } from 'luxon'

export const PtCarDrawerContext = createContext({})

const getPtcarGroup = groupData => {
    if (groupData.ptCarGroup === '' || groupData.ptCarGroup === null) return ''
    else return groupData.ptCarGroup
}
const StoreProvider = ({ rowData, edit, mapView, lineNumber, children }) => {
    function getCommercialNameFr(nameObj) {
        return nameObj && nameObj.length > 0 ? nameObj[0].translation : ''
    }

    function getCommercialNameNl(nameObj) {
        return nameObj && nameObj.length > 0 ? nameObj[1].translation : ''
    }

    const [countNormal, setCountNormal] = useState(0)
    // eslint-disable-next-line max-len
    const [ptCarGroup, setPtCarGroup] = useState(edit ? getPtcarGroup(rowData) : '')
    const [error, setError] = useState('')
    const [countLine, setCountLine] = useState(0)
    const [validFrom, setValidFrom] = useState(rowData.validFrom ? rowData.validFrom : DateTime.local().toSeconds())
    const [validTo, setValidTo] = useState(
        rowData.validTo
            ? rowData.validTo
            : DateTime.local()
                  .plus({ years: 20 })
                  .toSeconds()
    )
    const [shortNameFr, setShortNameFr] = useState(getCommercialNameFr(rowData.shortName))
    const [shortNameNl, setShortNameNl] = useState(getCommercialNameNl(rowData.shortName))
    const [longNameFr, setLongNameFr] = useState(getCommercialNameFr(rowData.longName))
    const [longNameNl, setLongNameNl] = useState(getCommercialNameNl(rowData.longName))
    const [commercialNameFr, setCommercialNameFr] = useState(getCommercialNameFr(rowData.commercialName))
    const [commercialNameNl, setCommercialNameNl] = useState(getCommercialNameNl(rowData.commercialName))
    const [commercialShortNameFr, setCommercialShortNameFr] = useState(getCommercialNameFr(rowData.commercialShortName))
    const [commercialShortNameNl, setCommercialShortNameNl] = useState(getCommercialNameNl(rowData.commercialShortName))
    const [commercialMediumNameFr, setCommercialMediumNameFr] = useState(getCommercialNameFr(rowData.commercialMediumName))
    const [commercialMediumNameNl, setCommercialMediumNameNl] = useState(getCommercialNameNl(rowData.commercialMediumName))
    const [classification, setClassification] = useState(rowData.classification ? rowData.classification : 1)
    const [code, setCode] = useState(rowData ? rowData.code : '')
    const [type, setType] = useState(rowData.type ? rowData.type : 0)
    const [netId, setNetId] = useState(rowData && rowData.netId !== undefined ? rowData.netId : 88)
    const [latitude, setLatitude] = useState(rowData.gpsCoordinate?.edrive ? parseFloat(rowData.gpsCoordinate.edrive.latitude) : 0)
    const [infrabelLatitude, setInfrabelLatitude] = useState(rowData.gpsCoordinate?.infrabel ? parseFloat(rowData.gpsCoordinate.infrabel.latitude) : 0)
    const [longitude, setLongitude] = useState(rowData.gpsCoordinate?.edrive ? parseFloat(rowData.gpsCoordinate.edrive.longitude) : 0)
    const [infrabelLongitude, setInfrabelLongitude] = useState(rowData.gpsCoordinate?.infrabel ? parseFloat(rowData.gpsCoordinate.infrabel.longitude) : 0)
    const [altitude, setAltitude] = useState(rowData.altitude ? parseFloat(rowData.altitude) : 0)
    const [infrabelaltitude, setInfrabelAltitude] = useState(rowData.altitude ? parseFloat(rowData.altitude) : 0)

    const [innerBox, setInnerBox] = useState(rowData && rowData.innerBox !== undefined ? rowData.innerBox : 450)
    const [outerBox, setOuterBox] = useState(rowData && rowData.outerBox !== undefined ? rowData.outerBox : 750)
    const [UIC, setUIC] = useState(rowData.uic)
    const [UicEdit, setUicEdit] = useState(false)
    const [ptCarKmMarks] = useState(() => {
        if (edit === 1) {
            if (mapView) {
                return rowData.ptCarKmMarks.find(ptCar => ptCar.lineNumber === lineNumber)
            }
            return rowData && rowData.ptCarKmMarks.length > 0 ? rowData.ptCarKmMarks[rowData.lineIndex] : false
        }
    })
    const [line] = useState(edit === 1 ? ptCarKmMarks?.lineNumber || '' : '')
    const [lineName] = useState(edit === 1 ? ptCarKmMarks?.lineName || '' : '')
    const [milestone] = useState(ptCarKmMarks ? ptCarKmMarks.milestone : '')
    const [mileId, setMileId] = useState(ptCarKmMarks ? ptCarKmMarks.mileId : '')
    const [order] = useState(ptCarKmMarks ? ptCarKmMarks.order : '')
    const [distance, setDistance] = useState(ptCarKmMarks ? ptCarKmMarks.distance : '')
    const [distanceToZero] = useState(ptCarKmMarks ? ptCarKmMarks.distanceToZero : '')
    const [distanceToNextPtCar] = useState(ptCarKmMarks ? ptCarKmMarks.distanceToNextPtcar : '')
    const [distanceToPreviousPtCar] = useState(ptCarKmMarks ? ptCarKmMarks.distanceToPreviousPtcar : '')
    const [ptCarLookupOther, setPtCarLookupOther] = useState(
            rowData.ptCarLookupField && rowData.ptCarLookupField.other.length > 0 
                ? [...rowData.ptCarLookupField.other, ''] : [''])
    
    const handlePtCarLookupChange = (val, index) => {
        let data = [...ptCarLookupOther]

        if(val != ''){
            if(data[index] == ''){
                data.push('')
            }
            data[index] = val
        }
        else{
            data[index] = val
            data.splice(index, 1)
        }

        setPtCarLookupOther(data)
    }

    const handlePtCarLookupDelete = (index) => {
        let data = [...ptCarLookupOther]
        data.splice(index, 1)
        setPtCarLookupOther(data)
    }

    const STORE = {
        countNormal,
        setCountNormal,
        ptCarGroup,
        setPtCarGroup,
        error,
        setError,
        countLine,
        setCountLine,
        validFrom,
        setValidFrom,
        validTo,
        setValidTo,
        shortNameFr,
        setShortNameFr,
        shortNameNl,
        setShortNameNl,
        longNameFr,
        setLongNameFr,
        longNameNl,
        setLongNameNl,
        commercialNameFr,
        setCommercialNameFr,
        commercialNameNl,
        setCommercialNameNl,
        commercialShortNameFr,
        setCommercialShortNameFr,
        commercialShortNameNl,
        setCommercialShortNameNl,
        commercialMediumNameFr,
        setCommercialMediumNameFr,
        commercialMediumNameNl,
        setCommercialMediumNameNl,
        classification,
        setClassification,
        code,
        setCode,
        type,
        setType,
        netId,
        setNetId,
        latitude,
        setLatitude,
        infrabelLatitude,
        setInfrabelLatitude,
        longitude,
        setLongitude,
        infrabelLongitude,
        setInfrabelLongitude,
        altitude,
        setAltitude,
        infrabelaltitude,
        setInfrabelAltitude,
        innerBox,
        setInnerBox,
        outerBox,
        setOuterBox,
        UIC,
        setUIC,
        UicEdit,
        setUicEdit,
        line,
        lineName,
        milestone,
        mileId,
        setMileId,
        order,
        distance,
        setDistance,
        distanceToZero,
        distanceToNextPtCar,
        distanceToPreviousPtCar,
        ptCarLookupOther,
        handlePtCarLookupChange,
        handlePtCarLookupDelete,
    }

    return <PtCarDrawerContext.Provider value={STORE}>{children}</PtCarDrawerContext.Provider>
}

StoreProvider.propTypes = {
    children: element,
    rowData: object,
    edit: number,
    mapView: bool,
    lineNumber: string,
}

export default StoreProvider
