export default theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
    submenu: {
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
    submenuTitle: {
        marginBottom: theme.spacing(2),
    },
    adHocDialog: {
        padding: '15px',
        minWidth: '300px',
    },
})