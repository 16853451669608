import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus, enums } from 'react-ion-store'
import PropTypes from 'prop-types'
import GeolocAdHocView from './GeolocAdHocView'
import Loading from 'components/Loading'

const {
    CallStatus: { LOADING, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>

const GeolocAdhoc = props => {
    const { store, __ion_status, history } = props
    const { userRoles } = __ion_status

    const { roles, moduleList } = store.get(['roles', 'moduleList'])

    const rolesLoading = !!userRoles && userRoles === LOADING
    const rolesFailed = !!userRoles && userRoles === FAILED

    if (rolesFailed)
        return (
            <React.Fragment>
                <Failed />
            </React.Fragment>
        )
    else if (rolesLoading)
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        )
    else if (!!moduleList && !moduleList.includes('/geolocadhoc')) {
        history.replace(`/`)
    }
    return (
        <React.Fragment>
            <GeolocAdHocView {...props} roles={roles}/>
        </React.Fragment>
    )
}
GeolocAdhoc.propTypes = {
    activeLanguage: PropTypes.object,
    translate: PropTypes.func,
    store: PropTypes.object,
    history: PropTypes.object,
    __ion_status: PropTypes.object,
}

export default compose(
    withRouter,
    withLocalize,
    withStore,
    withStatus,
)(GeolocAdhoc)
